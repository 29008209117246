import React from "react";
import { navigate } from "gatsby";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import styles from "../../../styles/Signin.module.scss";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import LoginLayout from "../../../components/LoginLayout";
import Connected from "../../../components/Onboarding/Connected";
import { LocationTypes } from "../../../components/Onboarding/ConnectBidddy";

const ConnectedPage = ({ location }) => {
  const navigationPath =
    location.state && location.state.navigationPath
      ? location.state.navigationPath
      : null;

  const handleClose = () => {
    if (navigationPath === LocationTypes.AuctionCreation) {
      navigate("/dashboard/auctions/connect/");
    } else {
      navigate("/dashboard/");
    }
  };

  return (
    <SimpleSpring location={location}>
      <SignUpHeader
        title="Connect to Instagram"
        onClose={() => handleClose()}
      />
      <AuthProvider>
        <LoginLayout hideHeader title="Bidddy - Connected Instagram">
          <div className={styles.loginContainer}>
            <Connected path={navigationPath} />
          </div>
        </LoginLayout>
      </AuthProvider>
    </SimpleSpring>
  );
};

export default ConnectedPage;
