import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import cn from "classnames";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import styles from "./Onboarding.module.scss";
import RoundedButton from "../Buttons/RoundedButton";
import Timeline from "../Timeline/Timeline";
import {
  selectInstagramBasicAccounts,
  selectUserInfoLoading,
} from "../../store/user/selectors";
import { InstagramBasicAccounts } from "../../store/user/actions";
import FullScreenLoading from "../Loading/FullScreenLoading";
import { AccountTypes } from "../../enums/AccountTypes";
import { getAuction } from "../../store/auctionModal/selectors";
import { Auction } from "../../types/Auction";
import { MethodTypes } from "../../enums/MethodTypes";
import LoadingSmall from "../Loading/LoadingSmall";

const ConnectedImage =
  "https://imgix.cosmicjs.com/965e2350-403c-11ec-9580-ebf669758fed-bidfootholdskateboard-1.png";

const Connected = ({ path = null, account = null }) => {
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const loading = useSelector((state) =>
    selectUserInfoLoading(state)
  ) as boolean;
  const instagramAccounts = useSelector((state) =>
    selectInstagramBasicAccounts(state)
  ) as InstagramBasicAccounts[] | null;

  const [accountType, setAccountType] = useState(account);
  const [localLoading, setLocalLoading] = useState(false);
  useEffect(() => {
    setLocalLoading(true);
  }, []);

  useEffect(() => {
    if (instagramAccounts && instagramAccounts.length > 0 && !account) {
      const firstAccount = instagramAccounts[0];
      // BUSINESS, MEDIA_CREATOR, or PERSONAL.
      setAccountType(firstAccount.account_type);
    }
    setLocalLoading(false);
  }, [instagramAccounts]);

  const getSubtitle = () => {
    if (accountType === AccountTypes.BUSINESS) {
      return "Great!";
    }
    if (auction && auction.type === MethodTypes.code) {
      return "Great!";
    }
    return "Switch!";
  };

  const getTitle = () => {
    switch (accountType) {
      case AccountTypes.BUSINESS:
        return (
          <h2>
            <span className={styles.pinkCopy}>
              {instagramAccounts && instagramAccounts.length > 0
                ? `@${instagramAccounts[0].username}`
                : ""}
            </span>{" "}
            is set up as a Business Account
          </h2>
        );
      case AccountTypes.MEDIA_CREATOR:
        return (
          <h2>
            <span className={styles.pinkCopy}>
              {instagramAccounts && instagramAccounts.length > 0
                ? `@${instagramAccounts[0].username}`
                : ""}
            </span>{" "}
            is set up as a Creator Account.
          </h2>
        );
      default:
        return (
          <h2>
            <span className={styles.pinkCopy}>
              {instagramAccounts && instagramAccounts.length > 0
                ? `@${instagramAccounts[0].username}`
                : ""}
            </span>{" "}
            is set up as a Personal Account.
          </h2>
        );
    }
  };

  const getParagraph = () => {
    switch (accountType) {
      case AccountTypes.BUSINESS:
        return (
          <p>
            We&apos;re almost there!{" "}
            <strong>Connect Instagram to Bidddy</strong> and you&apos;re ready
            to roll.
            <br />
            <br />
            Connecting is easy and only takes a minute. Instagram and Facebook
            will walk you through the steps.
          </p>
        );
      case AccountTypes.MEDIA_CREATOR:
        if (auction.type === MethodTypes.code) {
          return (
            <p>
              <strong>Connect Instagram to Bidddy</strong> and you&apos;re ready
              to roll.
              <br />
              <br />
              Connecting is easy and only takes a minute. Instagram and Facebook
              will walk you through the steps.
            </p>
          );
        }
        return (
          <p>
            To use Bidddy, you&apos;ll need to{" "}
            <strong>switch to an Instagram Business Account</strong>
            <br />
            <br />
            No problem! It&apos;s easy, quick and free to switch to a Business
            Account. To learn how by clicking <strong>Next</strong>...
          </p>
        );

      default:
        return (
          <p>
            To use Bidddy, you&apos;ll need to{" "}
            <strong>switch to an Instagram Business Account</strong>
            <br />
            <br />
            No problem! It&apos;s easy, quick and free to switch to a Business
            Account. To learn how by clicking <strong>Next</strong>...
          </p>
        );
    }
  };

  const next = () => {
    if (accountType === AccountTypes.BUSINESS) {
      navigate("/dashboard/getting-started/connect-instagram/", {
        state: { navigationPath: path, accountType },
      });
    } else if (accountType === AccountTypes.MEDIA_CREATOR) {
      if (auction && auction.type === MethodTypes.code) {
        navigate("/dashboard/getting-started/connect-instagram/", {
          state: { navigationPath: path, accountType },
        });
      } else {
        navigate("/dashboard/getting-started/switch-account/", {
          state: { navigationPath: path },
        });
      }
    } else {
      navigate("/dashboard/getting-started/switch-account/", {
        state: { navigationPath: path },
      });
    }
  };

  const getButtonText = () => {
    if (accountType === AccountTypes.BUSINESS) {
      return "Next";
    }
    if (accountType === AccountTypes.MEDIA_CREATOR) {
      if (auction && auction.type === MethodTypes.code) {
        return "Next";
      }
      return "Switch Account Now";
    }
    return "Switch Account Now";
  };

  const isPersonalOrInvalid = () => {
    if (
      accountType === AccountTypes.MEDIA_CREATOR &&
      auction &&
      auction.type === MethodTypes.schedule
    ) {
      return true;
    }
    if (accountType === AccountTypes.PERSONAL) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        className={cn([
          styles.howItWorksOnboardingContainer,
          styles.connectedContainer,
          styles.defaultContainer,
        ])}
      >
        <Timeline selectedDot={0} />
        {!loading && accountType && (
          <>
            <div className={styles.congratsImageContainer}>
              <Imgix
                src={`${ConnectedImage}?auto=format`}
                width={148}
                height={232}
                htmlAttributes={{
                  alt: `Connected Instagram to Bidddy`,
                  src: `${ConnectedImage}?auto=format`,
                }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
              />
            </div>
            <h3>{getSubtitle()}</h3>
            {getTitle()}
            {getParagraph()}
            <RoundedButton
              onClick={() => next()}
              disabled={instagramAccounts.length < 0 || localLoading}
              id="submit"
            >
              {!localLoading && getButtonText()}
              {localLoading && <LoadingSmall />}
            </RoundedButton>
            {isPersonalOrInvalid() && (
              <button type="button">How to Switch an Account?</button>
            )}
            {!isPersonalOrInvalid() && (
              <button type="button">Not the Right Account?</button>
            )}
          </>
        )}
      </div>
      {loading && <FullScreenLoading />}
    </>
  );
};

export default Connected;
